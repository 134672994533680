import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesTribeTower: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tribe Towers - Team Composition</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_tribe_tower_teams.webp"
            alt="Tribe Tower"
          />
        </div>
        <div className="page-details">
          <h1>Tribe Towers - Team Composition</h1>
          <h2>
            Struggling with clearing Tribe Towers? Check out our Guide below!
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          If you haven’t checked our previous guide about Meta Team, make sure
          to drop a view
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Meta teams (Story)"
            link="/nikke/guides/meta-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_beginner.png"
                alt="Early and late meta teams"
              />
            }
            updated
          />
        </Row>
        <p>
          This post is written under the assumption you have a proper knowledge
          about team building, because Tribe Tower forces you to use Nikke's
          from that manufacturer only. And read our Investment Guide as your
          books are precious!
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Skill investment Guide"
            link="/nikke/guides/skill-investment"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_skills.jpg"
                alt="Skill investment Guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Missilis Tower" />
        <p>
          Missilis can be considered “Easy Mode” because although their DPS are
          outdated, their supporters are the strongest currently. Thanks to
          those supporters, the DPS can distribute pretty meaningful damage even
          with low investment.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="laplace" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The current MVP of Missilis Tower, featuring 2 heavy drillers Laplace
          and Maxwell. This team excels in killing single targets like Boss,
          Dangerous Elite units and can do pretty well against mobs too thanks
          to Laplace splash damage + Drill burst (And she’s even stronger now
          due to Favorite system). Their DPS (mostly Maxwell if you have Laplace
          Treasure version) can be replaced somewhat with:
        </p>
        <h5>Replacements</h5>
        <p>
          <NikkeCharacter mode="inline" slug="ein" enablePopover />: the best
          AoE unit for Missilis Tribe Tower, and the best replacement for
          Laplace and Maxwell
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="epinel" enablePopover /> -
          Alternate AoE pick, weaker replacement to Ein, you can consider
          building her up to 7/4/7, but it isn’t recommended since this is a
          tower-only unit. Mostly you won’t need fast AoE Wiper, until you reach
          these nasty stages. (Tower 244F)
        </p>
        <StaticImage
          src="../../../images/nikke/generic/tribe_tower_1.webp"
          alt="Tribe Tower"
        />
        <p>
          <i>
            Very friendly stage, it welcomes you with 11 instant kill Raptures…
          </i>
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="drake" enablePopover />: Good
          Replacement for when you do not have Laplace or Maxwell. Can also be
          used for PvP, if you are in a tight spot for Synchro Device slots and
          want flexible units.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="kilo" enablePopover />: A limited
          time Nikke only available through Nikke’s “.5” half anniversary
          events. Kilo, just like Epinel, is another replacement for when you
          need an AoE screen-clear. She can be easier/harsher to use depending
          on the stage design, since her AoE depends on her own self having a
          shield!
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="elegg" enablePopover />: She’s
          another strong single target DPS too, but most of the time, she won’t
          find her place once you complete your ACE team above. But if you lack
          Tia or Naga to finish the duo, then you can consider using Elegg
          instead.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="centi" enablePopover />: Once a
          PVE god, now she is outclassed hard by Tia in PVE. Still, if you lack
          Tia/Naga, can use her as burst gen filler + shield provider.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="pepper" enablePopover />: A decent
          healer with high offensive power. She doesn’t reduce cooldown, but she
          heals a lot with her burst. Only when you don’t have Liter.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="quency" enablePopover />: A free
          unit from liberation, decent healer who periodically provides
          offensive buffs. Good replacement for B2 if you do not have Centi or
          Naga.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="n102" enablePopover />: Our very
          own budget liter, N102, is always a great choice to use if you happen
          to not have Liter or Pepper. If you have Tia Naga but no Liter, N102
          is probably better than Pepper for most stages.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="mihara" enablePopover />: The only
          option left if you have no SSR B3s. Mihara can fill in the spot until
          you pull someone better.
        </p>
        <SectionHeader title="Tetra Tower" />
        <p>
          Although Tetra supporters are not as busted as Missilis, Mustang has
          his trump card with Bunnies Goddess.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
        </div>
        <p>
          Alice is the real MVP here. Insane single target DPS with pierce
          allows her to clean a lot of mobs in very short time, even elite/boss
          too. And Xlud will provide a lot of DPS on her own while also
          increasing Alice damage.
        </p>
        <h5>Replacements</h5>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="soda-twinkling-bunny"
            enablePopover
          />
          : Easily the best AoE wiper for Tetra tower. You may need to swap
          Xludmilla for B.Soda on some stages, or when you don’t have her due to
          being seasonal limited.
        </p>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="rosanna-chic-ocean"
            enablePopover
          />
          : The latest limited Nikke who can replace Blanc to a certain degree.
          She has weaker dmg debuff but free another slot for you from Noir.
        </p>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="sakura-bloom-in-summer"
            enablePopover
          />
          : She has very nice damage from the very start (should be on par with
          Scarlet) and can only get stronger the more parts the boss has. But
          she’s also seasonal limited and you can use B.Soda to do whatever you
          need her to do.{' '}
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="yulha" enablePopover />: This is
          when you don’t have those limited units. You will have to rely on
          Yulha as AoE wiper outside Alice burst, because some stages are just
          pure evil!
        </p>
        <StaticImage
          src="../../../images/nikke/generic/tribe_tower_2.webp"
          alt="Tribe Tower"
        />
        <p>
          <i>
            Example of an evil stage, this player had to cope with Ludmilla!
          </i>
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="sugar" enablePopover />: Instead
          of Yulha, you may also use Sugar when the enemies are near your
          screen, like those shield mobs. Sugar can easily deal with them.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="noise" enablePopover />: Very
          strong “healer” and “taunter” in Nikke. Sometimes when Blanc alone is
          not enough, you may want to rely on Noise to tank those rough damage.
          She can be paired with Sakura if you lack Volume.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/tribe_tower_3.webp"
          alt="Tribe Tower"
        />
        <p>
          <i>Rebuilt Cucumber burns very hard</i>
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="mica-snow-buddy" enablePopover />/
          <NikkeCharacter mode="inline" slug="cocoa" enablePopover />/
          <NikkeCharacter mode="inline" slug="ade" enablePopover />: These girls
          are cleansers, and you can use whoever you have against VERY
          SITUATIONAL stages, for example, Sunbather, or Rebuilt Cucumber above.
          Mica and Cocoa can replace Volume (you will run 0 CDR team but keep
          good DPS), or Ade if you sacrifice your DPS for more CDR (single DPS
          Alice with Noir as support).
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="bay-goddess-mary" enablePopover />
          : She serves the same as Noise without taunt, but you don’t need to
          burst her to heal. Used on heavy heal required stages, but mind you,
          seasonal limited Nikke too!
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="novel" enablePopover />: Novel is
          a good option to use against Bosses when you lack bunnies.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="anis" enablePopover />: Our
          trusty, always adorable and funny Anis will always be there to fill in
          the spot for B2 if you lack other B2s.
        </p>
        <SectionHeader title="Elysion Tower" />
        <p>
          This is exclusive for Elysion Tribe Tower, because at the moment this
          guide was written, they have the worst supporters, the worst DPS and
          only one good yet very niche DPS.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guillotine" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="vesti" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the most commonly used team in Elysion tower. It has nice AoE
          wiping with Privaty, decent burst gen with Vesti, decent heal with
          Marciana and decent DPS with Guillotine. If your roster has these
          characters, you can safely raise them to push Elysion tower.
        </p>
        <h5>Replacements</h5>
        <p>
          <NikkeCharacter mode="inline" slug="helm" enablePopover />: You can
          replace Vesti with Helm but the burst gen and your mobs wiping power
          will be reduced a lot. This is mostly if you are not running Marciana
          in b2, or if you need extra heals to survive, or if you need added CP
          since most people have Helm invested, but Vesti remains uninvested due
          to not being a Meta Unit.
        </p>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="privaty-unkind-maid"
            enablePopover
          />
          : What’s better than Privaty? Only Privaty! MPrivaty has the strongest
          wipe in Elysion, very decent damage although she’s a Shotgun due to
          the specialty of her gun. She will replace either Vesti or Privaty
          depending on the stage you are fighting at. (Need a faster burst? Keep
          Vesti. Need better AoE? Keep Privaty.)
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="diesel" enablePopover />: Normal
          Diesel is not worth mentioning and only serves as B2 filler at max.
          But due to the new Treasure/Favorite system, she’s actually very
          strong as B2 in Elysion tower now, considering their main DPS is a 1
          HP Glass Canon MG. We have a dedicated guide for Diesel Treasure
          version here, please read for more information. Also by using Diesel,
          you can put Vesti back for better burst gen, because your Guillotine
          will reload even less now.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Diesel (Treasure) review"
            link="/nikke/guides/guide-diesel-treasure"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_diesel_tr.webp"
                alt="Diesel (Treasure)"
              />
            }
            isNew
          />
        </Row>
        <p>
          <NikkeCharacter mode="inline" slug="poli" enablePopover />/
          <NikkeCharacter mode="inline" slug="mast" enablePopover />/
          <NikkeCharacter mode="inline" slug="aqua-marine-helm" enablePopover />
          (Seasonal Limited): These characters can be used as a replacement for
          Marciana. If you have Helm: Aqua Marine, you should try using her over
          Marciana for stronger mob cleaning capability. As for the other 2,
          they are not as comfortable as our cute teacher, but it’s better than
          nothing. Remember to use another B2 instead of Vesti. if you are using
          Poli over Marciana. (Some high level stages don’t require burst much,
          so you can use Poli as solo B2, especially when they need you to rush
          the boss as fast as you can before they wipe you).
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="soline" enablePopover />/
          <NikkeCharacter mode="inline" slug="brid" enablePopover />: They can
          replace Guillotine, but remember, they require the same harsh
          condition as Guillotine, and the return is not even that impressive.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="miranda" enablePopover />:
          D:Killer Wife is the backbone of Elysion team, but you can run Miranda
          too if your wife somehow refuses going home. Why Miranda? Because the
          only other 20s B1 available, other than D:KW and Miranda, is Neon, who
          is an SR unit.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/elysion_tower.webp"
          alt="Tribe Tower"
        />
        <p>
          <i>Ingrid pleaseeeeee release good units for Tribe Tower...</i>
        </p>
        <SectionHeader title="Pilgrim Tower" />
        <p>
          Pilgrim is Easy Mode tower, that’s a fact. But there’s another sad
          fact: They rarely come home, so this tower is actually the hardest
          tower early on because you have to push sometimes with just 1 Nikke
          lmao.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is the most familiar team in Pilgrim Tower, with high single
          target damage, high wipe power, decent CDR.
        </p>
        <p>
          There are a few replacements for Red Hood, Modernia and Scarlet: Black
          Shadow here:
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover />: She has
          a good wipe, decent DPS on her own. On some stages, you are forced to
          use Scarlet instead of SBS for sure.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="snow-white" enablePopover />: Snow
          White will be used when you want to cheese some stages (just snipe the
          boss before the stage kills all your Nikke).
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="rapunzel" enablePopover />: Some
          stages force you to bring a healer, for example, Bronchus will punish
          your team harshly if you don’t have any healer.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="harran" enablePopover />: Great
          early character, but don’t over invest in her. Stay at max 5/5/5. She
          will be replaced the moment you have 3 great DPS mentioned above.
          Crown can also be replaced if you don’t have her, of course:
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="noah" enablePopover />: Noah has
          everything: Taunt, Defense Up, even invulnerable temporarily… in
          exchange for a 40s CD! And being a brat! Still, you can use her
          normally with Red Hood as another B2 if you can end stage under 3
          rotations (Noah =&gt; Red Hood =&gt; Noah)
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="nihilister" enablePopover />: You
          must be desperate to rely on a liberation character as B2. But don’t
          worry, we all did before Crown!
        </p>
        <p>
          On certain stages, you can (or you must) finish the stage under 2 ~ 3
          Burst rotations. This is the best team you can think of:
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
        </div>
        <p>
          You start the fight with Red Hood =&gt; Crown =&gt; Modernia. Then
          based on the situation, you hold the fight so Crown Burst is ready
          again, or you go with Rapunzel =&gt; Red Hood =&gt; Scarlet: Black
          Shadow immediately. Then finish the stage by either holding burst till
          SBS burst is ready again, or go with Red Hood =&gt; Crown =&gt;
          Modernia immediately, killing the mobs and then focusing the boss as
          much as possible in the remaining time. You may have to use this setup
          on Pilgrim Tower 245F.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTribeTower;

export const Head: React.FC = () => (
  <Seo
    title="Tribe Towers - Team Composition | NIKKE | Prydwen Institute"
    description="  Struggling with clearing Tribe Towers? Check out our Guide below!."
    game="nikke"
  />
);
